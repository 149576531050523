.App-header {
	min-height: 50vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 4vmin);
	text-align: center;
}

header{
	text-align: center;
}

body{
	//background-color: #282c34; //old back
	//background-color: #1f2d3b; //New background
}


//Scrollbar
/* width */
::-webkit-scrollbar {
	width: 0px;
}
  
  /* Track */
::-webkit-scrollbar-track {
	background-color: #1f2d3b;
}
   
  /* Handle */
::-webkit-scrollbar-thumb {
	background: #888; 
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555; 
}


//phone size




//normal size
@media screen and (min-width: 1024px){
	nav{
		display: flex;
		justify-content: space-evenly;
	
		div{
			display: flex;
			
		}
	}
}



html{
	font-size: 20px;
}
