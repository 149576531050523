$boxDimentions: 300px;
$imgDimentions: 200px;

.Project-Box{
	img{
		width: $imgDimentions;
		height: $imgDimentions;
		border-radius: 30px;
		object-fit: cover;
	}
	h3{
		font-size: 1.2rem;
	}
	p{
		
	}
	text-align: center;
	padding: 10px;
	width: $boxDimentions;
	height: $boxDimentions;
	margin: 10px;
	border-radius: 10px;
	overflow: hidden;
}

.Project-Box:hover{
	animation-name: boxDrop;
	animation-duration: 0.2s;
	animation-iteration-count: 1;
	animation-fill-mode: both;
}

@keyframes boxDrop{
	to{
		background-color: rgba(255, 255, 255, 0.473);
		//height: 310px;
		//width: 310px;
		transform: scale(1.1);
		box-shadow: 0px 0px 150px rgb(41, 41, 41);
	}
}
