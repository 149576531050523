main.about{
	padding: 1%;
	div{
		text-align: center;
		h2{
			font-size: 1.75rem;
		}
		img{
			width: 10rem;
			height: auto;
		}
		p{
	
		}
	}
}