.Project-Page{
	img{
		min-height: 400px;
		max-height: 600px;
		border-radius: 20px;
		object-fit: cover;
	}
	h2{

	}
	p{

	}
}